@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.App {
  text-align: center;
  color: white;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-image: url('./assets/img/background.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Poppins', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


header,
footer,
main {
  padding: 20px;
  text-align: center;
}

/* Admin.css */
.admin-container {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding-bottom: 50px;
}

.admin-header {
  text-align: center;
  padding-top: 100px;
  margin-bottom: 50px;
}

.admin-table th {
  background: #222222;
}

.admin-table tr:nth-child(odd) td {
  background: #2a2a2a;
}

.admin-table tr:nth-child(even) td {
  background: #333333;
}

.admin-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.admin-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.admin-user-card {
  background: #333;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.admin-user-card:hover {
  transform: translateY(-5px);
}

.admin-user-card strong {
  color: #ff043a;
}

.card-content p {
  margin: 10px 0;
  line-height: 1.4;
}

.card-actions {
  margin-top: 20px;
  text-align: right;
}

.card-actions button {
  background-color: #ff043a;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.card-actions button:hover {
  background-color: #e60000;
}



/* loading bar */
.loading-gif {
  display: block;
  margin: auto;
  width: 50px; /* Adjust size as needed */
  height: auto;
}

/* Instructions */
.authorize-network-section {
  background: green;
  max-width: 500px;
  margin: auto;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Profile Box */
.profile-box,
.logout-box
 {
  display: none;
}

button.change-password-button{
  display: flex;
  margin: 0 auto;
}

.account-details-text,
  .password-change-text{
    margin-top: 5px;
    text-align: center;
  }

/* Stripe Pay Button */
.payment-button.stripe {
  background-color: #6772E5; 
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  outline: none;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25);
  margin-bottom: 20px;
  max-width: 200px;
}

.payment-invoice-check{
  background-color: #00bd00; 
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  outline: none;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25);
}

.payment-invoice-check:hover{
  background-color: #00b118; 
  transform: translateY(-2px); 
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.3);
}

.payment-button.stripe:hover, .payment-button.stripe:focus {
  transform: translateY(-2px); 
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.3);
}

.payment-button.stripe:active {
  background-color: #555ABF; /* Even darker for the active state */
  box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.2);
  transform: translateY(0);
}

/* Coupon Manager */
.coupon-manager-container {
  max-width: 750px;
  margin: auto;
  background: #111;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
}

.coupon-manager-header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #555;
  margin-bottom: 20px;
}

.coupon-list {
  list-style: none;
  padding: 0;
}

.coupon-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: #333;
  padding: 10px;
  border-radius: 5px;
}

.coupon-code {
  font-weight: bold;
}

.coupon-discount {
  margin-left: 10px;
}

.delete-button {
  background-color: #ff043a;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #e60000;
}

.input-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.input-group input,
.input-group button {
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.input-group input {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.input-group button {
  background-color: #0d6efd;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.input-group button:hover {
  background-color: #0a58ca;
}


/* Header */
.header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #111111;
  color: white;
  padding: 0 0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 50px;
  transition: background-color 0.3s ease;
  padding: 10px;
}


.sidenav {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: width 0.5s ease;
  padding-top: 60px;
  z-index: 998;
}

.hamburger-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: none;
}

.close-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.sidenav a:hover {
  color: #f1f1f1;
}


.nav-logo {
  margin-right: 20px;
}

.nav-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.auth-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.profile-box-button{
  padding: 10px;
  margin-right: 20px;
}
.profile-box, .logout-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-box a, .logout-box a {
  text-decoration: none;
  color: white; 
  margin-left: 8px; 
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-content a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.nav-content a:hover {
  color: #ff043a;
}

.nav-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-home-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-text {
  margin-left: 10px;
  color: #FFF;
}

.header-logo {
  height: 40px;
  width: auto;
}

.pricing-container span.highlight,
.compatible-grid span.highlight,
.features-container span.highlight,
.support-container span.highlight,
.faq-container span.highlight {
  color: #ff043a;
}



.header.shrink {
  background-color: rgba(17, 17, 17, 0.5);

}

nav.header {
  content: center;
}

.header a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.discord-button {
  background-color: #ff043a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}


.account-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  /* Push footer to bottom */
}

.nav-buttons button {
  width: 100px;
  height: 30px;
  margin-left: 10px;
  background-color: #ff043a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
  font-size: 16px;
}

.nav-buttons button:hover {
  background-color: #ff043a;
  transform: scale(1.05);
}

.supported-hardware img:hover {
  transition: background-color 0.3s ease, transform 0.2s ease;
  transform: scale(1.05);
}

/* My Profile */
.password-change-section{
  margin-top: 20px;
}


/* Password Reset (correct with footer) */
.password-reset-container {
  display: flex;
  flex-direction: column;
  flex: 1; 
}

/* Checkout */
.checkout-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkout-title, .section-title {
  color: #333;
  text-align: center;
}

.invoice-details, .payment-options {
  margin-top: 20px;
}

.payment-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.payment-button.stripe {
  background-color: #6772e5;
}

.payment-button.stripe:hover {
  background-color: #5469d4;
}

.payment-button.paypal {
  background-color: #ffc439;
}

.payment-button.paypal:hover {
  background-color: #ffb700;
}

.checkout-loading {
  text-align: center;
  padding: 50px 0;
}

.checkout-section{
  height: 100vh;
}

.checkout-container{
  margin-top: 100px;
}

.coupon-validation-message {
  margin: 10px 0;
  color: #ff043a; 
}

.coupon-validation-message.success {
  color: #3fa34d; 
}

/* DNS checks */
.try-again-button{
  background: transparent; 
  border: none; 
  color: rgb(255, 4, 58); 
  font-weight: bold; 
  cursor: pointer; 
  outline: rgb(255, 4, 58) 
  solid 2px; 
  margin-left: 15px; 
  width: 100px;
  padding: 10px;
}

.back-profile-button{
  background: transparent; 
  border: none; 
  color: rgb(255, 4, 58); 
  font-weight: bold; 
  cursor: pointer; 
  outline: rgb(255, 4, 58) 
  solid 2px; 
  margin-left: 15px; 
  max-width: 200px;
  padding: 10px;
}

.dns-support-links{
  color:rgba(255,4,58);
}

/* Network card */
.reset-network-authorize-button {
  margin: 0 10px;
  background-color: #333;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  border-style: none;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  padding: 10px;
  margin-left:25px;
}

.ip-warning-message {
  color: #ff0000; 
  margin-top: 10px;
  text-align: center;
}

/* OTP PAGE */
.otp-input-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.otp-input {
  width: 40px;
  height: 50px;
  margin: 0 5px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.verify-otp-button {
  background-color: #ff043a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  display: block;
  margin: 20px auto;
  transition: background-color 0.3s;
}

.verify-otp-button:hover {
  background-color: #af0026;
}

/* Lazy loading */
.lazy-div {
  /* height: 50vh; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  font-size: 1.5em;
}

/* Card */
.status-card {
  background: #333;
  color: #fff;
  padding-bottom: 5px;
  border-radius: 5px;
}

.status-header {
  color: #fff;
  background: #222;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px 5px 0 0;
}

.status-content {
  padding-bottom: 25px;
  border-radius: 5px;
}

.status-indicator {
  color: #0f0;
  margin-bottom: 10px;
}

.status-checkmark {
  font-weight: bold;
  margin-right: 5px;
}

.status-help-text {
  max-width: 654px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.activated-ip {
  font-weight: bold;
}

.ip-address {
  margin-bottom: 20px;
}

.status-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.activate {
  background: #ffcc00;
  color: #222;
}

.deactivate {
  background: #d00;
  color: #fff;
}

.status-button:hover {
  opacity: 0.8;
}

/* Tab Component */
.tab-component {
  margin-top: 20px;
}

.tab-titles {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.tab-title {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  margin-right: 5px;
}

.tab-title.active {
  border-color: #ff043a;
  color: #ff043a;
}

.status-header,
.dns-selection-title {
  color: rgb(151 149 149);
}

.tab-panel {
  display: none;
}

.tab-panel.active {
  display: block;
}

.hidden {
  display: none;
}


/* PSN Card */
h2.psn-container-header {
  padding-left: 20px;
}

.psn-container-header {
  background: #ff043a;
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
}

/* PC card */
h2.pc-container-header {
  padding-left: 20px;
}


.pc-container-header {
  background: #ff043a;
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
}

/* Xbox card */
h2.xbox-container-header {
  padding-left: 20px;
}

.xbox-container-header {
  background: #ff043a;
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
}

/* VPN Card */
.hidden-checkbox {
  display: none;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.status-sweaty {
  color: #ff0000; 
  font-size: 12px;
}

.status-botty {
  color: #00ff00;
  font-size: 12px;
  margin-right: 20px;
}

.status-average {
  color: #ffa500;
  font-size: 12px;
  margin-right: -5px;
}

.location-time {
  color: rgb(195 191 191);
}


.country-name {
  text-align: left;
}

.country-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.country-flag {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.status-header {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.status-content {
  text-align: center;
}

.country-item input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.country-item label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.country-item .custom-checkbox {
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
  margin-right: 10px;
  position: relative;
}

.custom-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  background: white;
  border-radius: 3px;
  border: 1px solid #ddd;
}

.location-time {
  text-align: left;
  font-size: 12px;
  margin-left: 2px;
}

.country-item img.country-flag {
  width: 40px;
  height: 25px;
  margin-right: 10px;
  border-radius: 2px;
}

.country-item {
  margin-top: 10px;
}

.country-item:hover input~.custom-checkbox {
  background-color: #ccc;
}

.country-item input:checked~.custom-checkbox {
  background-color: #ff043a;
}

.custom-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}


.country-item input:checked~.custom-checkbox:after {
  display: block;
}

.country-item .custom-checkbox:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff043a;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #222;
  border-radius: 5px;
  max-width: 600px;
  height: 50px;
}

.loading-text {
  flex-grow: 0;
  margin-left: 10px;
}

/* Account */
.account-section-header h2 {
  text-align: left;
  vertical-align: middle;
  font-weight: 800;
  margin-bottom: 32px;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 0;
}

.authorize-network-section {
  box-shadow: #111 0px 7px 25px;
}

.account-second-section {
  padding: 50px;
  margin-top: 20px;
}

h1.faq-account-title {
  padding: 0px;
}

/* Signup */
.signup-form-container{
  padding: 20px;
}

.alerts-container {
  max-width: 300px;
  margin: 0 auto;
}

.signup-box-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  background-color: #111;
  color: white;
  margin: 170px auto 100px auto;
  max-width: 800px;
  border-radius: 5px;
}

.signup-form{
  max-width: 300px;
  margin: 0 auto; 
}

.signup-info-container {
  background-color: #222;
  width: 100%;
  padding: 20px 20px;
  border-radius: 0 5px 5px 0;
}

.signup-header {
  position: relative;
  margin-bottom: 20px;
  text-align: center;
}

.signup-header::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 80%;
  height: 2px;
  background-color: #ff043a;
  border-radius: 1px;
}

.signup-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #111;
  color: white;
  margin: 170px auto 100px auto;
  max-width: 800px;
  border-radius: 5px;
}

.signup-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.signup-content {
  flex: 1;
}

.signup-header {
  margin-bottom: 20px;
  text-align: center;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.signup-form label {
  margin-bottom: 5px;
  text-align: left;
  font-size: 12px;
}

.signup-form input {
  margin-bottom: 30px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.signup-button {
  background-color: #ff043a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  max-width: 100px;
  margin: 0 auto;
}

.signup-button:hover {
  background-color: darken(#680016, 10%);
}

.terms-text,
.already-account p {
  text-align: center;
  font-size: 0.8em;
  margin-top: 20px;
}

.signup-info-container {
  width: 40%;
}

.signup-info-list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

.signup-info-list li {
  margin-bottom: 10px;
}

.signup-gun-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

.signup-header span.highlight,
.terms-text span.highlight {
  color: #ff043a;
}

.already-account a,
.terms-text a {
  text-decoration: none;
  color: #ff043a;
}

.already-account a:hover,
.terms-text a:hover {
  text-decoration: underline;
}

/* invoice pay button */
.pay-invoice-button,
.check-payment-button{
  background-color: #23ad01;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



/* Invoies */
.invoices-container {
  max-height: 800px; 
  max-width: 1200px;
  overflow-y: auto;
  overflow-x: auto; 
  background: '#222';
  margin: 0 auto;
  
}

.my-profile-section {
  margin-top: 50px;
}

.invoice-section {
  height: 100vh;
}

.invoice-grid-row:nth-child(even) {
  background-color: #2a2a2a; 
}

.invoice-grid-row:nth-child(odd) {
  background-color: #333; 
}

.invoice-grid-header, .invoice-grid-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr); 
  text-align: left;
  padding: 10px;
  align-items: center;
}

.invoice-grid-header span, .invoice-grid-row span {
  text-align: left;
}


.invoice-grid-header > span {
  font-weight: bold;
}

.invoice-grid-row > span {
  padding: 5px 0;
}

.pay-invoice-button,
.check-payment-button {
  background-color: #ff043a;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pay-now-container {
  grid-column: 6 / -1; /* This will place the pay button in the last column */
}

/* Login */
.login-form-container {
  background-color: #111;
  width: 65%;
  padding: 20px;
  border-radius: 5px 0 0 5px;
}

.signup-container{
  height: 60vh;
}



/* terms-of-service */
.terms-container {
  flex: 1;
  margin-top: 175px;
  margin-bottom: 100px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}

.terms-header {
  text-align: center;
  margin-bottom: 20px;
}

.updated-terms {
  font-size: 0.7em;
}

.terms-title {
  color: #ffffff;
  font-size: 2em;
}

.terms-content {
  border-radius: 5px;
}

.terms-section {
  margin-bottom: 20px;
}

.terms-subtitle {
  color: #ff043a;
  font-size: 1.5em;
}

.terms-update-notice {
  font-size: 0.9em;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}

/* Faq Section*/
.FAQ-container {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: left;
  padding-left: 27%; 
  padding-right: 5%;
}

.FAQ-title{
  padding: 0px;
}

.faq-title-header{
  margin-top: 75px;
}

.faq-titles{
  color: rgb(255, 4, 58);
  text-decoration: none;
}

/* privacy*/
.privacy-container {
  flex: 1;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}

.privacy-header {
  text-align: center;
  margin-bottom: 20px;
}

.updated-privacy {
  font-size: 0.7em;
}

.privacy-title {
  color: #ffffff;
  font-size: 2em;
}

.privacy-content {
  border-radius: 5px;
}

.privacy-section {
  margin-bottom: 20px;
}

.privacy-subtitle {
  color: #ff043a;
  font-size: 1.5em;
}

.privacy-update-notice {
  font-size: 0.9em;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}

/* Footer */
.footer {
  background-color: #111111;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative;
}


.footer-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  align-items: start; 
}

.footer-section {
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 180px;
}

.footer-section a {
  text-decoration: none;
  color: white;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-weight: bold;
}

.footer-section {
  margin: auto;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover,
.footer-section a:hover {
  color: #ff043a;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.8em;
}



/* Home */
.home {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 0px;
}

.home-header-text {
  font-size: 52px;
  max-width: 500px;
  margin: 0 auto;
}

.home-header {
  color: #fff;
  text-align: center;
  padding: 100px 20px;
  margin-top: -100px;
}

.home-header h1 .highlight {
  color: #ff043a;
}

.subtitle {
  margin-top: 20px;
  font-size: 1em;
}

.support-text {
  margin-top: 20px;
  font-weight: bold;
  font-size: 0.7em;
  color: #ff043a;
  letter-spacing: 3px;
}

.supported-hardware img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

.button-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button {
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.buy-now {
  background-color: #ff043a;
}

.features {
  background-color: #28a745;
}

.button:hover {
  opacity: 0.8;
}

.button-container .button {
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  margin: 0 10px;
  background-color: #ff043a;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
}

.button-container a.button:nth-child(1) {
  background-color: #ff043a;
}

.button-container a.button:nth-child(2) {
  background-color: #353535;
}

.button-container .button:hover {
  background-color: #ff043a;
  transform: scale(1.1);
}

.button-container .button:first-child {
  margin-left: 0;
}

.homeBanner {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.features-container {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  padding: 50px 0;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  justify-items: center;
  max-width: 1200px;
}

.second-grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 30px;
  margin: 0 auto;
  justify-items: center;
  max-width: 900px;
}

.features-grid .feature-item {
  padding: 20px;
  border-radius: 10px;
}


.feature-item {
  padding: 20px;
  border-radius: 10px;
}


.feature-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.features-header {
  color: white;
  padding: 20px;
}

.features-subtitle .highlight {
  color: #ff043a;
}

.features-header .highlight {
  color: #ff043a;

}

.feature-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.feature-item img:hover {
  transition: background-color 0.3s ease, transform 0.2s ease;
  transform: scale(1.10);
}

.feature-item h2 {
  font-size: 15px;
}

.feature-item p {
  font-size: 15px;
}

.features-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  align-items: left;
  padding-bottom: 80px;
  grid-template-areas:
    "image text"
    "benefits benefits";
}

.feature-item-2:nth-child(1) {
  /* Assuming this is the image */
  grid-area: image;
}

.feature-item-2:nth-child(2) {
  /* Assuming this is the text */
  grid-area: text;
}

.benefits-container {
  grid-area: benefits;
}

.feature-item-2 p,
h1 {
  padding: 20px;
  border-radius: 10px;
}

.happy-gamer-img {
  width: 80%;
  height: 380px;
  margin-top: 100px;
  margin-bottom: 20px;
}

.benefits-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.benefit-item {
  background-color: #ff043a;
  color: white;
  padding: 8px 16px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.checkmark {
  margin-right: 5px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;

}

.compatible-grid {
  margin-top: 100px;
}

.how-header {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 80px;
}

.how-to-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.how-header h1 {
  text-align: center;
}

.how-to-item {
  text-align: center;
}

.how-to-item img {
  width: 15%;
  max-width: 200px;
  margin: 0 auto 20px;
}

.support-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 60px;
}

.support-text h1 {
  font-size: 32px;
  color: #fff;
}

.support-text p {
  color: #fff;
}

.support-image img {
  width: 50%;
  height: auto;
  margin-top: 100px;
}

.try-before h1 {
  padding: 0px;
}

.try-before p {
  margin: 0 auto;
  max-width: 800px;
}

.button-container-trial {
  margin-top: 30px;
  text-align: center;
}

.button.buy-now {
  display: inline-block;
  margin-bottom: 4px;
}

.button-subtitle {
  margin-top: 5px;
  display: block;
  font-size: 0.8em;
  color: #666;
}

.floating-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* Floating Button */
.floating-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #3f51b5;
  color: white;
  font-size: 24px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-button:hover {
  background-color: #133a69;
}

/* Floating Card */
.floating-card {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 300px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
  animation: slideIn 0.3s ease-out;
}

/* Animation */
@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Card Header */
.chat-card {
  position: absolute;
  bottom: 80px; /* Position above the button */
  right: 0;
  width: 350px;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #121212;
  color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 999;
}


.chat-header {
  background-color: #3f51b5;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.chat-header h3 {
  margin: 0;
}

.chat-log {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-message {
  text-align: right;
  color: #fff;
  margin-bottom: 10px;
}

.bot-message {
  font-family: 'Poppins', Arial, sans-serif, 'Segoe UI Emoji';
  font-size: 14px;
  color: #ddd;
  margin-bottom: 10px;
}


.chat-input-container {
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.chat-input-container input {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  border: none;
  margin-right: 5px;
}

.chat-input-container button {
  background: #3f51b5;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.game-mode-select {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.game-mode-select option {
  background: #1e1e1e;
  color: white;
}

button {
  padding: 5px 10px;
  background-color: #3f51b5;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #9c9c9c;
  cursor: not-allowed;
}

.user-message {
  align-self: flex-end;
  background-color: #4a4a4a;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.bot-message {
  align-self: flex-start;
  background-color: #3f51b5;
  color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.prediction-message {
  align-self: flex-start;
  background-color: #2e2e2e;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 80%;
  word-wrap: break-word;
  margin-bottom: 5px;
}

.flag-icon {
  width: 24px;
  height: 18px;
  border-radius: 3px;
}

.bot-message span.warning {
  color: orange;
  font-weight: bold;
}

/* Review */
.reviews-container {
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 35px;

}

.nav-buttons-container {
  display: flex;
  justify-content: center;
  /* Center buttons horizontally */
  margin-top: 20px;
  /* Space from the review content */
}

.feature-item-2-title {
  color: #ff043a;
}

.nav-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 100px;
  margin-right: 100px;
}

.review {
  max-width: 800px;
  margin: 0 20px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review-content {
  flex-grow: 1;
  text-align: center;
}

.review-text {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.reviewer-info {
  justify-content: center;
  margin-top: 10px;
}


.review-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.reviewer-name {
  font-weight: bold;
}

.reviewer-role {
  font-style: italic;
  font-size: 0.8rem;
  margin-top: 0px;
  color: #ff043a;
}

p.how-subtitle {
  max-width: 800px;
  margin: 0 20px;
  color: white;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* FAQ Component */
.faq-container {
  max-width: 600px;
  margin: 0 auto;
}

.faq-item {
  background-color: #333;
  color: #fff;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-question {
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  padding: 16px;
  border-top: 1px solid #444;
  /* To visually separate the question from the answer */
  font-size: 14px;
  text-align: left;
}

.faq-icon {
  font-weight: bold;
  color: #ff043a;
}

/* Youtube embed */
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-top: 160px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


/* Pricing */
.pricing-container {
  margin-top: 100px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  padding: 20px 0;
}

/* PricingCard.css */
.pricing-card {
  background-color: #333;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 300px;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.pricing-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 28%;
  background: rgba(0, 0, 0, 0.3);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  pointer-events: none;
}

.pricing-subtitle-text {
  font-size: 12px;
  color: #ffffff;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.pricing-title {
  color: #ff043a;
  font-size: 24px;
  margin-bottom: 10px;
}

.pricing-price {
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
}

.pricing-features li {
  margin-bottom: 8px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.pricing-features li::before {
  content: "✔";
  color: #ff043a;
  margin-right: 8px;
  font-size: 16px;
}

.btn {
  background-color: #ff043a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 80%;
  margin-top: 20px;
}

.btn:hover {
  background-color: #610000;
}

.pricing-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  /* Increase gap for more space between cards */
  margin: 40px 0;
  /* More vertical space */
}

.password-reset-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #111;
  color: white;
  margin: 170px auto 100px auto;
  max-width: 1200px;
  border-radius: 5px;
}

.password-reset-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.password-reset-content {
  flex: 1;
}

.password-reset-form-container {
  width: 40%;
}

.password-reset-header {
  margin-bottom: 20px;
  text-align: center;
}

.password-reset-form {
  display: flex;
  flex-direction: column;
}

.password-reset-form label {
  margin-bottom: 5px;
  text-align: left;
  font-size: 12px;
}

.password-reset-form input {
  margin-bottom: 30px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.password-reset-button {
  background-color: #ff043a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  max-width: 100px;
  margin: 0 auto;
}

.password-reset-button:hover {
  background-color: darken(#680016, 10%);
}

.support-image img {
  width: 80%;
  margin-top: 20px;
}

.dns-section {
  max-width: 400px;
  margin: 0 auto;
}

/* mobile/responsive design */
@media (max-width: 768px) {
  .review {
    max-width: 100%;
    margin: 0 10px;
    padding: 0 10px;
  }


  .psn-container-header {
    background: #ff043a;
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;

  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px;
  }

  .nav-content,
  .nav-buttons {
    display: none;
  }

  .nav-logo {
    display: flex;
    align-items: center;
  }


  .account-container {
    grid-template-columns: 1fr;
  }

  .password-reset-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #111;
    color: white;
    margin: 170px auto 100px auto;
    max-width: 1200px;
    border-radius: 5px;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-section {
    margin-bottom: 20px;
  }

  .signup-container,
  .login-form-container {
    flex-direction: column;
  }



  .signup-form-container,
  .login-form-container {
    border-radius: 5px;
    width: 100%;
  }

  .reviews-container {
    flex-direction: column;
  }

  .nav-button {
    margin-bottom: 10px;
  }

  .how-to-grid {
    grid-template-columns: 1fr;
  }

  .how-to-item img {
    width: 15%;
    max-width: 200px;
    margin: 0 auto 20px;
  }


  .footer,
  .h1,
  .p,
  .features-container,
  .faq-text,
  .pricing-subtitle,
  .try-before,
  .support-text,
  .support-text img {
    padding: 5px 10px;
  }

 
  .nav-buttons-container {
    flex-direction: row;
    width: 100%;
    padding: 0 10px;
  }


  .nav-button {
    flex: 1;
    margin: 0 10px;
  }


  .features-grid-2 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "text"
      "video"
      "text2"
      "image";
    gap: 10px;
  }

  .your-video-container {
    grid-area: video;
  }

  .your-second-text-container {
    grid-area: text2;
  }

  .feature-item-2:nth-child(2) {
    grid-area: image;
  }

  .feature-item-2:nth-child(2) {
    grid-area: text;
  }

  .support-container,
  .features-grid,
  .features-grid-2,
  .image-grid,
  .how-to-grid,
  .second-grid {
    grid-template-columns: 1fr;
  }

  .how-to-item img {
    width: 15%;
    height: auto;
    margin-bottom: 20px;
  }


  .support-image img {
    width: 100%;
    margin-top: 20px;
  }

  .happy-gamer-img,
  .ytp-cued-thumbnail-overlay-image {
    width: 70%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .nav-buttons button,
  .button-container .button {
    width: 80px;
    margin: 10px;
  }

  .button-container {
    flex-direction: row;
  }

  .benefit-item {
    font-size: 0.7rem;
  }

  header,
  footer,
  main,
  .home-header,
  .button-container-trial,
  .reviews-container {
    padding: 10px;
  }


  .features-header .highlight,
  .feature-item-2-title {
    font-size: 32px;
  }

  .subtitle,
  .support-text,
  .feature-item h2,
  .feature-item p {
    font-size: 0.9rem;
  }

  .account-second-section {
    padding: 20px;
  }

  .status-card {
    width: 100%;
    margin: 10px 0;
  }


  p.status-help-text {
    max-width: 400px;
    padding: 10px;
  }

  .country-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .country-list-2{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .location-changed {
    width: 80%;
  }

  .countries-container {
    flex-direction: column;
    align-items: center;
  }

  .country-list,
  .country-list-2 {
    width: 100%; 
  }

  .country-list-2{
    margin-top: -20px;
  }
  a.button.buy-now,
  a.button.features {
    width: 150px;
    margin: 0 5px;
    margin-bottom: 60px;
  }

  .faq-item {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 10px;
  }


  .profile-box,
  .logout-box,
  .logout-box {
    display: flex;
  }

  .logout-box{
    margin-right: 5px;
  }

  .account-second-section {
    padding: 20px;
    margin-top: 20px;
  }

  .status-header,
  .dns-selection-title,
  .location-time {
    color: rgb(195 191 191);
  }

  .home-header-text {
    font-size: 52px;
    max-width: 500px;
    margin: 30px;
  }

  .account-details-text,
  .password-change-text{
    margin-top: 5px;
    text-align: center;
  }

  .password-change-section, 
  .password-change-info-section, 
  .account-plan-section,
  .account-details-section,
  .accounts-details-info-section
  {
      width: 350px;
  }

  .signup-info-container {
    width: 80%;
  }

  .hamburger-icon {
    display: flex; 
    margin: '0 auto';
   
  }

  .pay-now-container {
    margin-top: 10px;
  }

  .invoices-container {
    max-height: 800px; /* Adjust based on your vertical space needs */
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: auto; /* Enables horizontal scrolling */
    background: '#222';
}

  .invoice-grid-row span,
  .invoice-grid-row .pay-now-container {
    display: block;
    margin-bottom: 10px;
    padding: 5px;
  }

  .pay-invoice-button,
  .check-payment-button {
    width: auto; 
    margin-left: 0;
    margin-top: 5px;
  }

  .status-sweaty {
    color: #ff0000; 
    font-size: 12px;
    text-align: left;

  }
  
  .status-botty {
    color: #00ff00;
    font-size: 12px;
    text-align: left;

  }
  
  .status-average {
    color: #ffa500;
    font-size: 12px;
    text-align: left;

  }

  .invoices-container {
    max-height: 300px; /* Smaller height for smaller screens */
}

.signup-box-container{
  flex-direction: column;
}


.img.PC1,
.img.PC2,
.img.PC3,
.img.PC4,
.img.PC5,
.img.PC6
{
  max-width: 100%;
}


.dns-section {
  max-width: 300px;
  margin: 0 auto;
}

.connection-section{
  margin-bottom: 50px;
}

.nav-logo {
  margin-right: 0px;
}

.otp-input{
margin: 0 auto;
}

p.reset-network-disc {
  max-width: 300px;
  margin: 0 auto;
}

/* faq */
.FAQ-container{
  padding: 20px;
}


}
